// Step2.js

import React, { useState } from "react";
import '../global.css';
import Icon from '@mdi/react';
import { mdiChevronDown, mdiChevronUp, mdiBullseyeArrow, mdiGlassFragile } from '@mdi/js';

function Step2({onSubmit, inputObj}) {

  // State to track visibility of each card content
  const [visibleCards, setVisibleCards] = useState(
    Object.fromEntries(inputObj.map(([causeOfAction]) => [causeOfAction, true]))
  );

  // Toggle visibility function
  const toggleVisibility = (causeOfAction) => {
    setVisibleCards((prevVisibleCards) => ({
      ...prevVisibleCards,
      [causeOfAction]: !prevVisibleCards[causeOfAction] // Toggle the visibility
    }));
  };
  

  return (
    <form onSubmit={onSubmit}>
      <div className="block">
        <h1 className="title is-4" style={{ marginBottom: '8px' }}>Legal Element Assessment</h1>
      </div>
      <div>

        {inputObj.map(([causeOfAction, value]) => (
          <div key={causeOfAction} className="card box is-draft" style={{ padding: '0' }}>
            <header className="card-header" style={{ boxShadow: 'none' }}>
              <h3 className="card-header-title title is-6" style={{ marginBottom: "0px", padding: '16px 24px 16px 24px' }}>{causeOfAction}</h3>
              <button
                type="button"
                className="card-header-icon"
                aria-label="toggle visibility"
                onClick={() => toggleVisibility(causeOfAction)}
              >
                <span className="icon">
                  <i
                    className={`fas ${visibleCards[causeOfAction] ? "fa-angle-up" : "fa-angle-down"}`}
                    aria-hidden="true"
                  ></i>
                </span>
                
              </button>
            </header>
            {visibleCards[causeOfAction] && (
              <div className="card-content" style={{ padding: '0px 24px 12px 24px' }}>
                <div className="content">
                  {value.description && (
                    <p className="body" style={{ marginBottom: '20px' }}>{value.descrpiton}</p>
                  )}
                  <b className="title is-7" style={{ marginBottom: '8px' }}>Legal element checker</b>

                  {value.elements.map((element, index) => (
                    <div key={index} className="table-row" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                      <div style={{ display: 'flex', alignItems: 'center', width: '100%', padding: '0px 8px' }}>
                        {/* <b className="body" style={{ fontWeight: '600', flex: 1, display: 'flex', alignItems: 'center', paddingRight:'16px' }}>
                        {index+1}. {element.name} - {element.source}
                        </b> */}
                        <b className="body" style={{ fontWeight: '600', flex: 1, display: 'flex', alignItems: 'center', paddingRight:'16px', flexDirection: 'column', alignItems: 'flex-start' }}>
                          <span>{index+1}. {element.name}</span>
                          {Array.isArray(element.source) ? (
                            element.source.map((source, i) => (
                              <span key={i} style={{ fontSize: '0.9em', opacity: 0.8 }}>{source}</span>
                            ))
                          ) : (
                            <span style={{ fontSize: '0.9em', opacity: 0.8 }}>{element.source}</span>
                          )}
                          {/* If you'd like to show citations: */}
                          {element.source_info && element.source_info.length > 0 && (
                            <ul>
                              {element.source_info.map((citation, cIndex) => (
                                <li key={index}>
                                <a 
                                  href={citation.type === 'state' 
                                    ? citation.prefix 
                                    : `${citation.prefix}/${citation.citationName}${citation.id ? `&${citation.id}` : ''}`} 
                                  target="_blank" 
                                  rel="noopener noreferrer"
                                >
                                  {citation.displayName}
                                </a>
                              </li>
                              ))}
                            </ul>
                          )}
                        </b>
                        <span>
                          {element.satisfied ? (
                            <span className="tag met" disabled>Likely met</span>
                          ) : (
                            <span className="tag not-met" disabled>Caution</span>
                          )}
                        </span>
                      </div>
                      
                      <div style={{ width: '100%' }}>
                        <div>
                        {!element.satisfied && element.analysis && ( //focus here.
                          <div style={{ marginTop: "12px", marginBottom: "8px", backgroundColor: "#F5F5F5", borderRadius: "8px", padding: "8px 12px" }}>
                            {element.analysis.split("\n").map((line, index) => {
                              let heading = "";
                              let iconPath = null;
                              if (index === 0) {
                                heading = "Weakness";
                                iconPath = mdiGlassFragile;
                              } else if (index === 1) {
                                heading = "Focus of Challenge";
                                iconPath = mdiBullseyeArrow;
                              } else {
                                heading = "Other"; // for lines beyond the second
                              }
                        
                              return (
                                <div key={index} className="list-legal">
                                  <div style={{ display: "flex", alignItems: "center" }}>
                                    {iconPath && (
                                      <Icon path={iconPath} size={0.9} aria-hidden="true" style={{ paddingRight: '4px', color: '#B29F7B' }}/>
                                    )}
                                    <p className="p bold">{heading}</p>
                                  </div>
                                  <p style={{ margin: 0 }}>{line}</p>
                                </div>
                              );
                            
                            })}
                          </div>
                        )}
                        </div>  
                      </div>                     
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>

        <div className="has-text-right">
          <button className="button is-normal is-success" type="submit" style={{ marginTop:'20px'}}>View recommendations</button>
        </div>
      </form>
  );
}

export default Step2;