// Step1.js

import React, { useState } from 'react';
import '../global.css';
import Icon from '@mdi/react';
import { mdiChevronDown, mdiChevronUp } from '@mdi/js';

function Step1({onSubmit, coas, fas}) {
  const [showFactual, setShowFactual] = useState(false); 
  const [showCauses, setShowCauses] = useState(false); 

  const toggleFactual = () => setShowFactual((prev) => !prev);
  const toggleCauses = () => setShowCauses((prev) => !prev);

  return (
    <div>
      <div className="block">
        <h1 className="title is-4" style={{ marginBottom: '8px' }}>Summary</h1>
      </div>
      
      <form onSubmit={onSubmit}>
        
        {/* Factual Allegations Section */}
        <div className="card box is-draft" style={{ padding: 0 }}>
          <header className={`card-header ${showFactual ? 'is-expanded' : ''}`} style={{ boxShadow: 'none' }}>
            <h3 className="card-header-title title is-7" style={{ margin: '0px 0px 0px 16px' }}>Factual Allegations</h3>
            <button type="button" className="card-header-icon" aria-label="toggle visibility" onClick={toggleFactual} style={{ border: 'none', background: 'none', cursor: 'pointer' }}>
              <Icon path={showFactual ? mdiChevronUp : mdiChevronDown} size={1}/>
            </button>
          </header>
          {showFactual && (
            <div className="card-content" style={{ padding: '0px 16px 16px 16px' }}>
              <ul style={{ listStyleType: 'disc', paddingLeft: '20px', maxWidth: '540px' }}>
                {fas.map((item, index) => (
                  <li key={index} className='body list'>
                    {item}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
        
        {/* Causes of Action Section */}
        <div className="card box is-draft" style={{ padding: 0 }}>
          <header className={`card-header ${showCauses ? 'is-expanded' : ''}`} style={{ boxShadow: 'none' }}>
            <h3 className="card-header-title title is-7" style={{ margin: '0px 0px 0px 16px' }}>Causes of Action</h3>
            <button type="button" className="card-header-icon" aria-label="toggle visibility" onClick={toggleCauses} style={{ border: 'none', background: 'none', cursor: 'pointer' }}>
              <Icon path={showCauses ? mdiChevronUp : mdiChevronDown} size={1}/>
            </button>
          </header>
          {showCauses && (
            <div className="card-content" style={{ padding: '0px 16px 16px 16px' }}>
              <ol style={{ paddingLeft: '20px', maxWidth: '540px' }}>
                {coas.map((item, index) => (
                  <li key={index} className='body list'>
                    <div style={{ whiteSpace: 'pre-wrap' }}>{item}</div>
                  </li>
                ))}
              </ol>
            </div>
          )}
        </div>

        <div className="has-text-right" style={{ marginTop: '16px' }}>
          <button className="button is-normal is-success" type="submit">
            Legal element assessment
          </button>
        </div>
      </form>
    </div>
  );
}

export default Step1;
