// Step3.js

import React from 'react';
import '../global.css';


function Step3({onSubmit, recomendations}) {
  return (
    <div>      
      <div className="block" style={{ marginBottom: '8px' }}>
        <h1 className="title is-4">Recommendations</h1>
      </div>

      <div className='block'>You can make edits directly in the input box. Your changes will be saved and available for download in the next step.</div>

      <form onSubmit={onSubmit}>
        {/* <div className='block'>
        <textarea className="textarea" name="recomendations" id="recomendations" defaultValue={recomendations.body} rows="20" cols="60"></textarea>
        </div> */}
        {recomendations.map(([causeOfAction, recArray]) => (
          <div key={causeOfAction} style={{ marginBottom: '1.5rem' }}>
            <h2 className="subtitle">{causeOfAction}</h2>

            <ul>
              {recArray.map((recObj, index) => (
                <li key={index} style={{ marginBottom: '0.5rem' }}>
                  <strong>Recommendation {index + 1}:</strong> {recObj.recommendation}

                  {recObj.citations_content && recObj.citations_content.length > 0 && (
                    <ul>
                      {recObj.citations_content.map((citation_content, cIndex) => (
                        <li key={cIndex}>
                          <strong>Citation:</strong> {citation_content}
                        </li>
                      ))}
                    </ul>
                  )}
                  
                  {/* If you'd like to show citations: */}
                  {recObj.citations && recObj.citations.length > 0 && (
                    <ul>
                      {recObj.citations.map((citation, cIndex) => (
                        <li key={index}>
                        {/* <a href={`${citation.prefix}/${citation.id}`} target="_blank" rel="noopener noreferrer">
                          {citation.displayName}
                        </a> */}
                        <a 
                          href={citation.type === 'state' 
                            ? citation.prefix 
                            : `${citation.prefix}/${citation.citationName}${citation.id ? `&${citation.id}` : ''}`} 
                          target="_blank" 
                          rel="noopener noreferrer"
                        >
                          {citation.displayName}
                        </a>
                      </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </div>
        ))}

        <div className="has-text-right">
          <button className="button is-normal is-success" type="submit">Proceed to download</button>
        </div>
      </form>
    </div>
  );
}

export default Step3;
